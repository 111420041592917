/* eslint react/jsx-indent: 0 */
import { ForgeButton } from '@tylertech/forge-react';
import Flyout from 'common/components/Flyout';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { userCanChangeAudience } from 'common/components/AccessManager/Util';
import { getCurrentUser } from 'common/current_user';
import { FeatureFlags } from 'common/feature_flags';
import formatString from 'common/js_utils/formatString';
import { fetchTranslation } from 'common/locale';
import { View } from 'common/types/view';
import { Params } from 'datasetManagementUI/lib/types';
import * as Links from 'datasetManagementUI/links/links';
import { Link } from 'react-router';
import React, { Component, PropsWithChildren } from 'react';
import noop from 'lodash/noop';

const FLYOUT_ID = 'publication-readiness-flyout';
const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.home_pane.publish_dataset_button');

function LabelledCheckmark({
  checked,
  text,
  children
}: PropsWithChildren<{ checked: boolean; text: string }>) {
  let checkmark;
  if (checked) {
    checkmark = <SocrataIcon className="checked" name={IconName.CheckmarkAlt} />;
  } else {
    checkmark = (
      <span className="not-checked">
        <svg>
          <circle r="7" cx="7.5" cy="7.5" />
        </svg>
      </span>
    );
  }
  return (
    <li>
      {checkmark}
      <div className="checkmark-body">
        <span className="checkmark-label">{text}</span>
        {children}
      </div>
    </li>
  );
}

function YouCantPublish({ view }: { view: View }) {
  return (
    <Flyout text="">
      <div className="you-cant-publish">
        <p>
          <b>{t('dont_have_rights')}</b>
        </p>
        <p>
          {view.owner && view.owner.id != getCurrentUser()?.id
            ? formatString(t('contact_data_owner'), { owner: view.owner.displayName })
            : ''}
        </p>
      </div>
    </Flyout>
  );
}

interface FlyoutProps {
  params: Params;
  readyToPublish: boolean;
  metadataSatisfied: boolean;
  requiresHref: boolean;
  hrefSatisfied: boolean;
  requiresData: boolean;
  dataSatisfied: boolean;
}

function PublishReadinessFlyout({
  params,
  readyToPublish,
  metadataSatisfied,
  requiresHref,
  hrefSatisfied,
  requiresData,
  dataSatisfied
}: FlyoutProps) {
  const buttonText = t('publish_ready');
  const flyoutText = t('cant_publish_until');
  return (
    <div className="flyout-content">
      {readyToPublish ? (
        <div className="flyout-content-ready">
          {buttonText}
          <ul>
            <li>{t('review_changes')}</li>
            <li>{t('add_viewers')}</li>
          </ul>
        </div>
      ) : (
        <div>
          {flyoutText}
          <ul>
            <></>
            <LabelledCheckmark checked={metadataSatisfied} text={t('metadata_satisfied')}>
              {!metadataSatisfied ? (
                <Link data-testid="publish-button-fix-metadata-link" to={Links.datasetMetadataForm(params)}>
                  {t('fix_metadata')}
                </Link>
              ) : null}
            </LabelledCheckmark>
            {!requiresData || <LabelledCheckmark checked={dataSatisfied} text={t('data_satisfied')} />}
            {!requiresHref || <LabelledCheckmark checked={hrefSatisfied} text={t('href_satisfied')} />}
          </ul>
        </div>
      )}
    </div>
  );
}

type USAIDFlyoutProps = FlyoutProps & {
  requiresParent: boolean;
  hasParent: boolean;
};

function USAIDPublishReadinessFlyout({
  readyToPublish,
  metadataSatisfied,
  requiresHref,
  hrefSatisfied,
  requiresData,
  dataSatisfied,
  requiresParent,
  hasParent
}: USAIDFlyoutProps) {
  const buttonText = t('submit_for_review_flyout');
  const flyoutText = t('cant_submit_until');

  return (
    <div className="flyout-content">
      {readyToPublish ? (
        buttonText
      ) : (
        <div>
          {flyoutText}
          <ul>
            {!requiresParent || <LabelledCheckmark checked={hasParent} text={t('parenthood_satisfied')} />}
            <LabelledCheckmark checked={metadataSatisfied} text={t('metadata_satisfied')} />
            {!requiresData || <LabelledCheckmark checked={dataSatisfied} text={t('data_satisfied')} />}
            {!requiresHref || <LabelledCheckmark checked={hrefSatisfied} text={t('href_satisfied')} />}
          </ul>
        </div>
      )}
    </div>
  );
}

interface Props {
  params: Params;
  view: View;
  metadataSatisfied: boolean;
  requiresHref: boolean;
  hrefSatisfied: boolean;
  requiresData: boolean;
  dataSatisfied: boolean;
  requiresParent: boolean;
  hasParent: boolean;
  applyableState: boolean;
  onClick: () => void;
  hasPublishingAbilities: boolean;
  readyToPublish: boolean;
}

class PublishButton extends Component<Props> {
  render() {
    const {
      view,
      params,
      metadataSatisfied,
      requiresHref,
      hrefSatisfied,
      requiresData,
      dataSatisfied,
      requiresParent,
      hasParent,
      applyableState,
      hasPublishingAbilities,
      readyToPublish
    } = this.props;

    const canPublish = userCanChangeAudience(getCurrentUser()) && hasPublishingAbilities;
    const isUSAID = FeatureFlags.value('usaid_features_enabled');

    let flyout;

    if (canPublish) {
      const flyoutProps = {
        readyToPublish,
        metadataSatisfied,
        requiresHref,
        hrefSatisfied,
        requiresData,
        dataSatisfied,
        hide: applyableState,
        params
      };
      flyout = isUSAID ? (
        <USAIDPublishReadinessFlyout {...{ ...flyoutProps, requiresParent, hasParent }} />
      ) : (
        <PublishReadinessFlyout {...flyoutProps} />
      );
    } else {
      flyout = <YouCantPublish view={view} />;
    }

    return (
      <div className="publish-button-container">
        <Flyout text={flyout}>{this.renderPublishButton(isUSAID, canPublish)}</Flyout>
      </div>
    );
  }

  renderPublishButton(isUSAID: any, canPublish: boolean) {
    const disabled = !canPublish || !this.props.readyToPublish || this.props.applyableState;
    const onClickAction = disabled ? noop : this.props.onClick;

    return (
      <ForgeButton type="raised" onClick={onClickAction}>
        <button type="button" className="publish-button" disabled={disabled}>
          {isUSAID && t('submit_for_review')}
          {isUSAID || t('publish_dataset')}
        </button>
      </ForgeButton>
    );
  }
}

export default PublishButton;
